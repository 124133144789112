<template>
    <div>
        <CompContent class="monitoring-index" title="监控告警 - 概览">
            <div class="modal-box">
                <div class="item-box" v-for="item in moduleList" :style="{ width: 'calc(' + 100 / moduleList.length + '% - 20px)' }">
                    <div class="name">{{ item.type }}</div>
                    <div class="value">{{ item.info }}</div>
                    <div class="hint-box">
                        <div class="name">{{ getTypeName(item) }}</div>
                        <div class="value">{{ item.total }}</div>
                    </div>
                </div>
            </div>
            <div class="detail-table">
                <ElemTable :columns="columns" :dataList="dataList" height="500px"></ElemTable>
            </div>
            <ElemChart :option="chartOpt" style="flex-shrink: 0;height: 300px;margin: 20px 10px 10px;width: initial;overflow: hidden;"></ElemChart>
        </CompContent>

        <CompForm title="处理" ref="comp_form" :forms="forms" height="auto" :submitBefore="onSubmitBefore" submitApi="/gateway/lf-monitor/api/monitor/AlarmInfo/handleAlarm"></CompForm>
    </div>
</template>

<script>
import ElemTable from "../communitymobilization/components/ElemTable.vue"
import CompContent from "../customform/components/CompContent.vue"
import CompForm from "../jointly/components/CompForm.vue"
import Request from "../jointly/utils/request"
import ElemChart from "../managsystem/childrens/ElemChart.vue"

// class Req {
//     static get(url) {
//         return new Promise((resolve, reject) => {
//             const xhr = new XMLHttpRequest()

//             xhr.open("GET", "https://api.gateway.prod.liefengtech.com" + url.replace("/gateway", ""), true)

//             xhr.setRequestHeader("Authorization", "4a82bed5-2a4e-40e2-9163-0c2684c58c5b")

//             xhr.onreadystatechange = function() {
//                 if (xhr.readyState === 4) {
//                     if (xhr.status === 200) {
//                         const data = JSON.parse(xhr.responseText)
//                         resolve(data.data || data.dataList)
//                     } else {
//                         reject(xhr.responseText)
//                     }
//                 }
//             }

//             xhr.send()
//         })
//     }
// }

export default {
    components: {
        CompContent,
        ElemTable,
        ElemChart,
        CompForm,
    },

    data() {
        return {
            columns: [
                {
                    title: "分组",
                    key: "alarmType",
                    minWidth: 120,
                },
                {
                    title: "异常描述",
                    key: "alarmMsg",
                    minWidth: 300,
                },
                {
                    title: "最近发生时间",
                    key: "alarmTime",
                    minWidth: 150,
                },
                {
                    title: "等级",
                    key: "alarmLevel",
                    minWidth: 80,
                },
                {
                    title: "状态",
                    minWidth: 120,
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.handleStatus === "待处理" ? "red" : "",
                                },
                            },
                            params.row.handleStatus
                        )
                    },
                },
                {
                    title: "操作",
                    minWidth: 80,
                    fixed: "right",
                    render: (h, params) => {
                        if (params.row.handleStatus === "待处理") {
                            return h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        margin: "0 3px",
                                    },
                                    on: {
                                        click: () => {
                                            this.process_id = params.row.id
                                            this.$refs.comp_form.open()
                                        },
                                    },
                                },
                                "处理"
                            )
                        }

                        return ""
                    },
                },
            ],

            forms: [
                {
                    type: "textarea",
                    title: "处理描述",
                    name: "handleDesc",
                    required: true,
                },
            ],

            chartOpt: null,

            moduleList: [],
            dataList: [],
        }
    },

    mounted() {
        this.getViewStat()
        this.getTableData()
    },

    methods: {
        getViewStat() {
            Request.get("/gateway/lf-monitor/api/monitor/AlarmInfo/getOverview").then(res => {
                this.moduleList = res
            })
        },

        getTableData() {
            Request.get("/gateway/lf-monitor/api/monitor/AlarmInfo/listAlarmInfo").then(res => {
                this.dataList = res

                // 判断日期为今天
                function isToday(date) {
                    var today = new Date() // 创建一个表示当前日期时间的Date对象
                    return (
                        date.getFullYear() === today.getFullYear() && // 年份相等
                        date.getMonth() === today.getMonth() && // 月份相等
                        date.getDate() === today.getDate()
                    ) // 日期相等
                }

                const group = {}

                res.forEach(item => {
                    let date = new Date(item.alarmTime)
                    if (!isToday(date)) {
                        return
                    }

                    if (!group[item.alarmType]) group[item.alarmType] = []

                    let hour = date.getHours()

                    // 查找
                    let find = group[item.alarmType].find(v => v.hour == hour)

                    if (find) {
                        find.value += 1
                    } else {
                        group[item.alarmType].push({
                            hour: date.getHours(),
                            value: 1,
                        })
                    }
                })

                // 24 小时数组
                const hours = Array.from({ length: 24 }, (v, i) => i)

                this.chartOpt = {
                    title: {
                        text: "当天告警时段",
                        left: "left",
                        textStyle: {
                            fontSize: 16,
                        },
                    },
                    xAxis: {
                        type: "category",
                        data: hours,
                        axisLabel: {
                            interval: 0,
                            fontSize: 10,
                            formatter: function(value) {
                                var strs = []

                                // 每隔4个字符换行
                                for (var i = 0, s; (s = value.substr(i, 4)); i += 4) {
                                    strs.push(s)
                                }

                                return strs.join("\n")
                            },
                        },
                    },
                    grid: {
                        top: 50,
                        left: 40,
                        right: 40,
                        bottom: 40,
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: Object.keys(group).map(k => {
                        return {
                            name: k,
                            data: hours.map(item => {
                                const find = group[k].find(v => v.hour == item)
                                return find ? find.value : new Date().getHours() > item ? 0 : null
                            }),
                            type: "line",
                            smooth: true,
                            label: {
                                show: true,
                                position: "top",
                            },
                        }
                    }),
                }
            })
        },

        onSubmitBefore(data) {
            data.id = this.process_id
            data.handler = parent.vue.loginInfo.userinfo.userName
        },

        getTypeName(item) {
            return (
                {
                    服务器: "当天告警总数",
                    应用服务: "正在运行",
                    redis: "正在运行",
                    消息队列: "当前告警总数",
                    定时任务: "当天告警总数",
                }[item.type] || "总数"
            )
        },
    },
}
</script>

<style lang="less" scoped>
.monitoring-index {
    .modal-box {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;

        .item-box {
            margin: 10px;
            width: calc(25% - 20px);
            height: 120px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            min-width: 200px;

            .name {
                font-size: 14px;
                color: #909399;
            }
            .value {
                font-size: 24px;
                color: #303133;
            }
            .hint-box {
                display: flex;
                justify-content: space-between;
                .name {
                    font-size: 14px;
                    color: #909399;
                }
                .value {
                    font-size: 14px;
                    color: #303133;
                }
            }
        }
    }

    .detail-table {
        margin: 0 10px 10px 10px;
    }
}
</style>
